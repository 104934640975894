const esDateTranslations = {
	days: {
		"monday": "lunes",
		"tuesday": "martes",
		"wednesday": "miércoles",
		"thursday": "jueves",
		"friday": "viernes",
		"saturday": "sábado",
		"sunday": "domingo"
	},
	months: {
		"january": "enero",
		"february": "febrero",
		"march": "marzo",
		"april": "abril",
		"may": "mayo",
		"june": "junio",
		"july": "julio",
		"august": "agosto",
		"september": "septiembre",
		"october": "octubre",
		"november": "noviembre",
		"december": "diciembre"
	},
	intervals: {
		"week": "semana",
		"month": "mes",
		"year": "año"
	}
}

export default esDateTranslations