import { useEffect, useState } from "react";
import ReportTable from "./table-report";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import styles from "./table.module.css";
import { DateUtils, StringUtils, WebUtils } from "../scripts/utils";
import services from "../scripts/services";
// import { faBriefcaseClock } from "@fortawesome/free-solid-svg-icons";

const isDictionaryEmpty = (data) => {
    let _emptyFlag = false;
    let _key;

    for (_key in data) {
        let _report = data[_key];
        if (Object.keys(_report).length === 0) {
            _emptyFlag = true;
        }
    }

    return _emptyFlag
}

const Reports = ({ SEARCH_INPUT_REGEX, isAdvancedSearchVisible, searchParameters, allIndustries, strings, isMobile, user, isProUser, justVerified, justUpgraded, youngestReportPeriod, userLang }) => {
    const [reportsDoc, setReportsDoc] = useState(null);

    // Set translation strings
    let translationLocale;
    switch (userLang) {
        case ("es"):
            translationLocale = "es-MX";
            break;
        default:
            translationLocale = "en-US";
            break;
    }

    // Remember: only pro users can view historical reports
    let _report = searchParameters["report"];

    // Set string representation of report date based on user's locale
    let _reportDateObj = null;
    let _reportDateFull = null;
    if (null !== _report) {
        _reportDateObj = DateUtils.newDateObject(null, null, null, null, _report, "underscore-year-first");
        _reportDateFull = _reportDateObj.toLocaleString(translationLocale, { weekday: "long", year: "numeric", month: "short", day: "numeric"});    
    }

    useEffect(() => {
        if (null !== _report) {
            // Get the user access token
            // let _token;
            // if (null !== user) {
            //     _token = user.accessToken
            // } else {
            //     _token = "none"
            // }

            // Get the user's email verification status
            let _isVerified;
            try {
                _isVerified = user.emailVerified;
            } catch {
                _isVerified = false;
            }

            // Set the command string to get youngest report period
            let _command = {
                "action": "get-reports",
                "report-period": _report,
                "is-verified": _isVerified,
                "is-pro-user": isProUser,
                "just-verified": justVerified,
                "just-upgraded": justUpgraded
            }
            let _command_str = JSON.stringify(_command);

            WebUtils.request(services["webServices"], _command_str)
            .then((_data) => {
                if (null !== _data ) { // This will occur if request fails                    
                    _data = StringUtils.cleanCRNL(_data);

                    if ("" === _data) {
                        setReportsDoc(false);
                    } else {
                        _data = JSON.parse(_data);
                        let _isEmpty = isDictionaryEmpty(_data);
                        if (_isEmpty) {
                            setReportsDoc(false);  
                        } else {
                            setReportsDoc(_data); 
                        }                      
                    }
                }
            })
            .catch((err) => console.error(err));
        };
        return () => {
            setReportsDoc(null);
        };
    }, [_report, user, isProUser, justVerified, justUpgraded]);
    
    if (null === _report) {
        return(
            <div className = { styles.loadingMessage }>{ strings.reportMessages.waiting["label"] }</div>
        )
    } else {
        if (null === reportsDoc) {
            return(
                <div className = { styles.loadingMessage }>{ strings.reportMessages.loading["label"].replace("$", _reportDateFull) }</div>
            )
        } else if (false === reportsDoc){
            return(
                <div className = { styles.loadingMessage }>{ strings.reportMessages.noData["label"].replace("$", _reportDateFull) }</div>
            )
        } else {
            // Set default tab to first report in `reportsDoc`
            let _defaultTab = Object.keys(reportsDoc)[0];                
            
            return(
                <Tabs defaultActiveKey = { _defaultTab }>
                    {
                        Object.keys(reportsDoc).map((report) => {
                            let _tabTitle;
                            if (isMobile) {
                                _tabTitle = strings.tableTitles.report[report]["abbr"];
                            } else {
                                _tabTitle = strings.tableTitles.report[report]["full"];
                            }
                            return(
                                <Tab key = { report } eventKey = { report } title = { _tabTitle }>
                                    <ReportTable
                                        SEARCH_INPUT_REGEX = { SEARCH_INPUT_REGEX }
                                        tabTitle = { _tabTitle }
                                        report = { report }
                                        data = { reportsDoc[report] }
                                        isAdvancedSearchVisible = { isAdvancedSearchVisible }
                                        searchParameters = { searchParameters }
                                        allIndustries = { allIndustries }
                                        strings = { strings }
                                        isMobile = { isMobile }
                                        youngestReportPeriod = { youngestReportPeriod }
                                        userLang = { userLang }>
                                    </ReportTable>
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            )                
        }
    }
}

export default Reports