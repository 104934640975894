import { Fragment } from "react";
// import SubNavItemDivWrapper from "./subnav-item-div-wrapper.jsx";
// import styles from "./subnav-area.module.css";

const SubNavArea = ({ subNavStrings, selectedSubNavOption, handlers }) => {    
    return(
        <Fragment></Fragment>
    )
    // return(
    //     <Fragment>
    //         <div className = { styles.subNavContainer }>
    //             <nav className = { styles.desktopNav }>
    //                 {
    //                     Object.keys(subNavStrings).map((key, index) => (SubNavItemDivWrapper(subNavStrings[key], index, selectedSubNavOption, handlers)))
    //                 }
    //             </nav>              
    //         </div>         
    //     </Fragment>
    // )
}

export default SubNavArea