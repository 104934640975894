import React, { useEffect, useState } from "react";
import { auth } from "../scripts/firebase";
import { applyActionCode } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import pStyles from "./verify-email.module.css";
import wStyles from "./forms-workflow.module.css";

const ids = {
    "area": { "id": "verifyEmailArea" },
    "apply": { "id": "verifyEmailApplyPanel" },
    "success": { "id": "verifyEmailSuccessPanel" }
}

const VerifyEmail = ({ setJustVerified, strings }) => {
    const { oobCode } = useParams();
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState(""); 

    const strContent = {
        applyPanelTitle: StringUtils.capitalizeSentences(strings.formContent.verifyEmail.applyPanel.title),
        applyPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.verifyEmail.applyPanel.subTitle),
        successPanelTitle: StringUtils.capitalizeSentences(strings.formContent.verifyEmail.successPanel.title),
        successPanelSubTitle: StringUtils.capitalizeSentences(strings.formContent.verifyEmail.successPanel.subTitle),
        submitDoneLbl: StringUtils.capitalizeWords(strings.formContent.buttons.submitDoneLabel),
        generalErrorNotice: StringUtils.capitalizeSentences(strings.formContent.notices.generalError)
    }
    
    const transitionToSuccessPanel = () => {
        document.getElementById(ids.success.id).style.left = "0";
    }

    const endVerifyEmail = (e) => {
        e.preventDefault();
        navigate(process.env.REACT_APP_PATH);
    }

    useEffect(() => {
        const applyVerifyEmailCode = async () => {
            try {
                // let _url = window.location.href;
                // let _params = _url.slice(_url.indexOf("?") + 1);
                // const _searchParams = new URLSearchParams(_params);
                // let _code = _searchParams.get("oobCode");
                let _code = oobCode;
                await applyActionCode(auth, _code);
                
                // Transition to success panel
                transitionToSuccessPanel();

                // Update user verification status
                setJustVerified(true);
            } catch {
                setTimeout(() => {
                    setStatusMessage(strContent.generalErrorNotice);
                }, 3000);
            }
        }

        applyVerifyEmailCode();
    })

    return(
        <div id = { ids.area.id } className = { [wStyles.workflowAreaContainer, "container-fluid"].join(" ") }>
            <div className = "row">
                <div className = { ["col-md-12", wStyles.leftCol, pStyles.leftCol].join(" ") }>
                    <div className = "row justify-content-center">
                        <div className = { [wStyles.formsContainer, "col-md-4"].join(" ") }>
                            <div className = "row row-cols-auto flex-nowrap">
                                <div id = { ids.apply.id } className = { ["col-12", wStyles.panel1].join(" ") }>                                        
                                    <div className = "row align-items-center">
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.applyPanelTitle }</h3>
                                            { statusMessage && <Alert variant = "info">{ StringUtils.capitalizeSentences(statusMessage) }</Alert> }
                                            <p className = "fs-5">{ strContent.applyPanelSubTitle }</p>                                      
                                        </form>
                                    </div>
                                </div>
                                <div id = { ids.success.id } className = { ["col-12", wStyles.panel2].join(" ") }>                                        
                                    <div className = "row align-items-center">
                                        <form className = "d-inline-block mt-3">
                                            <h3 className = "mb-3">{ strContent.successPanelTitle }</h3>
                                            <p className = "fs-5">{ strContent.successPanelSubTitle }</p>       
                                            <div className = "d-grid gap-2">
                                                <button type = "submit" className = "btn btn-secondary pt-3 pb-3 text-capitalize" onClick = { (e) => endVerifyEmail(e) }>{ strContent.submitDoneLbl }</button>
                                            </div>                                       
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )    
}

export default VerifyEmail