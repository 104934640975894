const esSICTranslations = {
	"TRADE & SERVICES": "COMERCIO Y SERVICIOS",
	"ENERGY & TRANSPORTATION": "ENERGÍA Y TRANSPORTE",
	"MANUFACTURING": "FABRICACIÓN",
	"TECHNOLOGY": "TECNOLOGÍA",
	"FINANCE": "FINANZAS",
	"LIFE SCIENCES": "CIENCIAS BIOLÓGICAS", 	
	"AGRICULTURE PRODUCTION CROPS": "PRODUCCIÓN AGRÍCOLA - CULTIVOS",
	"AGRICULTURE PRODUCTION LIVESTOCK ANIMAL SPECIALTIES": "PRODUCCIÓN AGRÍCOLA - GANADO Y ESPECIALIDADES ANIMALES",
	"AGRICULTURE SERVICES": "SERVICIOS AGRICOLAS",
	"METAL MINING": "MINERÍA DE METALES",
	"GOLD SILVER ORES": "MINERALES DE ORO Y PLATA",
	"MISCELLANEOUS METAL ORES": "MINERALES METÁLICOS VARIOS",
	"BITUMINOUS COAL LIGNITE MINING": "MINERÍA DE CARBÓN BITUMINOSO Y LIGNITO",
	"BITUMINOUS COAL LIGNITE SURFACE MINING": "MINERÍA SUPERFICIE DE CARBÓN BITUMINOSO Y LIGNITO",
	"CRUDE PETROLEUM NATURAL GAS": "PETRÓLEO CRUDO Y GAS NATURAL",
	"DRILLING OIL GAS WELLS": "PERFORACIÓN DE POZOS DE PETRÓLEO Y GAS",
	"OIL AND GAS FIELD EXPLORATION SERVICES": "SERVICIOS DE EXPLORACIÓN DE CAMPOS DE PETRÓLEO Y GAS",
	"OIL GAS FIELD SERVICES NBC": "SERVICIOS DE CAMPO DE PETRÓLEO, GAS, NBC",
	"MINING QUARRYING OF NONMETALLIC MINERALS NO FUELS ": "MINERÍA, EXTRACCIÓN DE MINERALES NO METÁLICOS (SIN COMBUSTIBLES)",
	"GEN BUILDING CONTRACTORS RESIDENTIAL BUILDINGS": "CONTRATISTAS DE CONSTRUCCIÓN DE GEN - EDIFICIOS RESIDENCIALES",
	"OPERATIVE BUILDERS": "CONSTRUCTORES OPERATIVOS",
	"GENERAL BUILDING CONTRACTORS NONRESIDENTIAL BUILDINGS": "CONTRATISTAS GENERALES DE CONSTRUCCIÓN - EDIFICIOS NO RESIDENCIALES",
	"HEAVY CONSTRUCTION OTHER THAN BUILDING CONST CONTRACTORS": "CONSTRUCCIÓN PESADA DISTINTA DE LA CONSTRUCCIÓN DE EDIFICACIONES - CONTRATISTAS",
	"WATER SEWER PIPELINE COMM AND POWER LINE CONSTRUCTION": "CONSTRUCCIÓN DE LÍNEAS DE AGUA, ALCANTARILLADO, TUBERÍAS, COMUNICACIONES Y ENERGÍA ELÉCTRICA",
	"CONSTRUCTION SPECIAL TRADE CONTRACTORS": "CONTRATISTAS COMERCIALES ESPECIALES DE CONSTRUCCIÓN",
	"ELECTRICAL WORK": "TRABAJO ELÉCTRICO",
	"FOOD KINDRED PRODUCTS": "ALIMENTOS Y PRODUCTOS AFINES",
	"MEAT PACKING PLANTS": "PLANTAS ENVASADORAS DE CARNE",
	"SAUSAGE OTHER PREPARED MEAT PRODUCTS ": "SALCHICHAS Y OTROS PRODUCTOS CÁRNICOS PREPARADOS",
	"POULTRY SLAUGHTERING AND PROCESSING": "SACRIFICIO Y PROCESAMIENTO DE AVES DE CORRAL",
	"DAIRY PRODUCTS": "PRODUCTOS LÁCTEOS",
	"CANNED FROZEN PRESERVED FRUIT VEG FOOD SPECIALTIES": "ESPECIALIDADES DE FRUTAS, VERDURAS Y ALIMENTOS ENLATADOS, CONGELADOS Y CONSERVADOS",
	"CANNED FRUITS VEG PRESERVES JAMS JELLIES": "CONSERVAS, FRUTAS, VERDURAS Y CONSERVAS, MERMELADA Y JALEA",
	"GRAIN MILL PRODUCTS": "PRODUCTOS DE MOLINILLO DE GRANO",
	"BAKERY PRODUCTS": "PRODUCTOS DE PANADERÍA",
	"COOKIES CRACKERS": "GALLETAS Y CRACKERS",
	"SUGAR CONFECTIONERY PRODUCTS": "AZÚCAR Y PRODUCTOS DE CONFITERÍA",
	"FATS OILS": "GRASAS Y ACEITES",
	"BEVERAGES": "BEBIDAS",
	"MALT BEVERAGES": "BEBIDAS DE MALTA",
	"BOTTLED CANNED SOFT DRINKS CARBONATED WATERS": "REFRESCOS EMBOTELLADOS Y ENLATADOS AGUAS CARBONADAS",
	"MISCELLANEOUS FOOD PREPARATIONS KINDRED PRODUCTS": "PREPARACIONES ALIMENTARIAS VARIAS Y PRODUCTOS AFINES",
	"TOBACCO PRODUCTS": "PRODUCTOS DE TABACO",
	"CIGARETTES": "CIGARRILLOS",
	"TEXTILE MILL PRODUCTS": "PRODUCTOS DE MOLINO TEXTIL",
	"BROADWOVEN FABRIC MILLS COTTON": "MOLINOS DE TEJIDO ANCHO, ALGODÓN",
	"BROADWOVEN FABRIC MILS MAN MADE FIBER SILK": "MILS DE TEJIDO ANCHO, FIBRA ARTÍFICA Y SEDA",
	"CARPETS AND RUGS": "ALFOMBRAS Y MOTOS",
	"APPAREL OTHER FINISHED PRODS OF FABRICS SIMILAR MATERIAL": "ROPA Y OTROS PRODUCTOS TERMINADOS DE TELAS Y MATERIALES SIMILARES",
	"MEN'S BOYS' FURNISHINGS WORK CLOTHING AND ALLIED GARMENTS": "MOBILIARIO PARA HOMBRES Y NIÑOS, ROPA DE TRABAJO Y PRENDAS AFINES",
	"WOMEN'S MISSES' AND JUNIORS OUTERWEAR": "ROPA ABRIGO PARA MUJERES, SEÑORAS Y JÓVENES",
	"WOMEN'S MISSES' CHILDREN'S INFANTS' UNDERGARMENTS": "ROPA INTERIOR PARA MUJERES, SEÑORAS, NIÑOS Y BEBÉS",
	"LUMBER WOOD PRODUCTS NO FURNITURE ": "MADERA Y PRODUCTOS DE MADERA (NO MUEBLES)",
	"SAWMILLS PLANNING MILLS GENERAL": "ASERRADEROS, PLANIFICACIONES, GENERAL",
	"MILLWOOD VENEER PLYWOOD STRUCTURAL WOOD MEMBERS": "MILLWOOD, CHAPA, MADERA CONTRACHAPADA Y MIEMBROS DE MADERA ESTRUCTURAL",
	"MOBILE HOMES": "CASAS MÓVILES",
	"HOUSEHOLD FURNITURE": "MUEBLES DEL HOGAR",
	"WOOD HOUSEHOLD FURNITURE NO UPHOLSTERED ": "MUEBLES DOMÉSTICOS DE MADERA, (NO TAPIZADOS)",
	"OFFICE FURNITURE": "MUEBLES DE OFICINA",
	"OFFICE FURNITURE NO WOOD ": "MUEBLES DE OFICINA (SIN MADERA)",
	"PUBLIC BUILDING AND RELATED FURNITURE": "EDIFICIO PÚBLICO Y MOBILIARIO AFINES",
	"MISCELLANEOUS FURNITURE FIXTURES": "MUEBLES Y ACCESORIOS VARIOS",
	"PULP MILLS": "MOLINOS DE PULPA",
	"PAPER MILLS": "Molinos de papel",
	"PAPERBOARD MILLS": "MOLINOS DE CARTÓN",
	"PAPERBOARD CONTAINERS BOXES": "ENVASES Y CAJAS DE CARTÓN",
	"CONVERTED PAPER PAPERBOARD PRODS NO CONTAINERS/BOXES ": "PRODUCTOS DE PAPEL Y CARTÓN CONVERTIDOS (SIN CONTENEDORES/CAJAS)",
	"PLASTICS FOIL COATED PAPER BAGS": "BOLSAS DE PLÁSTICO, LÁMINA Y PAPEL ESTUCHADO",
	"NEWSPAPERS PUBLISHING OR PUBLISHING PRINTING": "PERIÓDICOS: PUBLICACIÓN O PUBLICACIÓN E IMPRESIÓN",
	"PERIODICALS PUBLISHING OR PUBLISHING AND PRINTING": "PERIÓDICAS: PUBLICACIÓN O PUBLICACIÓN E IMPRESIÓN",
	"BOOKS PUBLISHING OR PUBLISHING AND PRINTING": "LIBROS: PUBLICACIÓN O PUBLICACIÓN E IMPRESIÓN",
	"MISCELLANEOUS PUBLISHING": "EDICIONES VARIAS",
	"COMMERCIAL PRINTING": "IMPRESIÓN COMERCIAL",
	"MANIFOLD BUSINESS FORMS": "MÚLTIPLES FORMAS DE NEGOCIO",
	"BLANKBOOKS LOOSELEAF BINDERS BOOKBINDING RELATED WORK": "LIBROS EN BLANCO, ENCUADERNACIONES DE HOJAS SUELTAS Y ENCUADERNACIÓN Y TRABAJOS RELACIONADOS",
	"CHEMICALS ALLIED PRODUCTS": "QUÍMICOS Y PRODUCTOS ALIADOS",
	"INDUSTRIAL INORGANIC CHEMICALS": "QUÍMICOS INORGÁNICOS INDUSTRIALES",
	"PLASTIC MATERIAL SYNTH RESIN/RUBBER CELLULOS NO GLASS ": "MATERIAL PLÁSTICO, RESINA SINTÉTICA/CAUCHO, CELULOS (SIN VIDRIO)",
	"PLASTICS MATERIALS SYNTH RESINS NONVULCAN ELASTOMERS": "PLÁSTICOS, MATERIALES, RESINAS SINTÉTICAS Y ELASTÓMEROS NO VULCANOS",
	"MEDICINAL CHEMICALS BOTANICAL PRODUCTS": "PRODUCTOS QUÍMICOS MEDICINALES Y BOTÁNICOS",
	"PHARMACEUTICAL PREPARATIONS": "PREPARACIONES FARMACÉUTICAS",
	"IN VITRO IN VIVO DIAGNOSTIC SUBSTANCES": "SUSTANCIAS PARA DIAGNÓSTICO IN VITRO E IN VIVO",
	"BIOLOGICAL PRODUCTS NO DIAGNOSTIC SUBSTANCES ": "PRODUCTOS BIOLÓGICOS (NO SUSTANCIAS DE DIAGNÓSTICO)",
	"SOAP DETERGENT CLEANING PREPARATIONS PERFUMES COSMETICS": "JABONES, DETERGENTES, PREPARADOS DE LIMPIEZA, PERFUMES, COSMÉTICOS",
	"SPECIALTY CLEANING POLISHING AND SANITATION PREPARATIONS": "PREPARACIONES ESPECIALES DE LIMPIEZA, PULIDO Y SANEAMIENTO",
	"PERFUMES COSMETICS OTHER TOILET PREPARATIONS": "PERFUMES, COSMÉTICOS Y OTROS PREPARADOS DE ASEO",
	"PAINTS VARNISHES LACQUERS ENAMELS ALLIED PRODUCTS": "PINTURAS, BARNICES, LACAS, ESMALTES Y PRODUCTOS ALIADOS",
	"INDUSTRIAL ORGANIC CHEMICALS": "QUÍMICOS ORGÁNICOS INDUSTRIALES",
	"AGRICULTURE CHEMICALS": "QUÍMICOS AGRÍCOLAS",
	"MISCELLANEOUS CHEMICAL PRODUCTS": "PRODUCTOS QUIMICOS VARIOS",
	"ADHESIVES SEALANTS": "ADHESIVOS Y SELLADORES",
	"PETROLEUM REFINING": "REFINACIÓN DE PETRÓLEO",
	"MISCELLANEOUS PRODUCTS OF PETROLEUM COAL": "PRODUCTOS VARIOS DE PETRÓLEO Y CARBÓN",
	"TIRES AND INNER TUBES": "NEUMÁTICOS Y CÁMARAS INTERIORES",
	"RUBBER PLASTICS FOOTWEAR": "CALZADO DE CAUCHO Y PLÁSTICO",
	"GASKETS PACKAGING AND SEALING DEVICES RUBBER PLASTIC HOSE": "JUNTAS, DISPOSITIVOS DE EMBALAJE Y SELLADO Y MANGUERA DE GOMA Y PLÁSTICO",
	"FABRICATED RUBBER PRODUCTS ": "PRODUCTOS FABRICADOS DE CAUCHO, NEC",
	"MISCELLANEOUS PLASTIC PRODUCTS": "PRODUCTOS PLÁSTICOS VARIOS",
	"UNSUPPORTED PLASTICS FILM SHEET": "PELÍCULAS Y HOJAS DE PLÁSTICO SIN SOPORTE",
	"PLASTICS FOAM PRODUCTS": "PRODUCTOS DE ESPUMA DE PLÁSTICO",
	"PLASTICS PRODUCTS ": "PRODUCTOS PLÁSTICOS, NEC",
	"LEATHER LEATHER PRODUCTS": "CUERO Y PRODUCTOS DE CUERO",
	"FOOTWEAR NO RUBBER ": "CALZADO, (SIN GOMA)",
	"FLAT GLASS": "VIDRIO PLANO",
	"GLASS GLASSWARE PRESSED OR BLOWN": "VIDRIO, CRISTALERÍA, PRENSADO O SOPLADO",
	"GLASS CONTAINERS": "ENVASES DE VIDRIO",
	"GLASS PRODUCTS MADE OF PURCHASED GLASS": "PRODUCTOS DE VIDRIO, FABRICADOS DE VIDRIO COMPRADO",
	"CEMENT HYDRAULIC": "CEMENTO HIDRÁULICO",
	"STRUCTURAL CLAY PRODUCTS": "PRODUCTOS DE ARCILLA ESTRUCTURAL",
	"POTTERY RELATED PRODUCTS": "CERÁMICA Y PRODUCTOS RELACIONADOS",
	"CONCRETE PRODUCTS EXCEPT BLOCK BRICK": "PRODUCTOS DE HORMIGÓN, EXCEPTO BLOQUES Y LADRILLOS",
	"CUT STONE STONE PRODUCTS": "PIEDRA CORTADA Y PRODUCTOS DE PIEDRA",
	"ABRASIVE ASBESTOS MISC NONMETALLIC MINERAL PRODUCTS": "ASBESTO ABRASIVO Y PRODUCTOS MINERALES NO METÁLICOS VARIOS",
	"STEEL WORKS BLAST FURNACES ROLLING FINISHING MILLS": "ACIERRAS, ALTOS HORNOS Y LAMINADORES Y ACABADOS",
	"STEEL WORKS BLAST FURNACES ROLLING MILLS COKE OVENS ": "ACIERRIAS, ALTOS HORNOS LAMINADORES (HORNOS DE COQUE)",
	"STEEL PIPE TUBES": "TUBERÍAS Y TUBOS DE ACERO",
	"PRIMARY SMELTING REFINING OF NONFERROUS METALS": "FUNDICIÓN PRIMARIA Y REFINACIÓN DE METALES NO FERROSOS",
	"PRIMARY PRODUCTION OF ALUMINUM": "PRODUCCIÓN PRIMARIA DE ALUMINIO",
	"SECONDARY SMELTING REFINING OF NONFERROUS METALS": "FUNDICIÓN SECUNDARIA Y REFINACIÓN DE METALES NO FERROSOS",
	"ROLLING DRAWING EXTRUDING OF NONFERROUS METALS": "ESTIRADO Y EXTRUSIÓN DE METALES NO FERROSOS",
	"DRAWING AND INSULATING NONFERROUS WIRE": "ESTIRADO Y AISLAMIENTO DE ALAMBRE NO FERROSO",
	"NONFERROUS FOUNDRIES CASTINGS ": "FUNDICIONES NO FERROSAS (CASTINGS)",
	"MISCELLANEOUS PRIMARY METAL PRODUCTS": "PRODUCTOS METÁLICOS PRIMARIOS VARIOS",
	"METAL CANS": "LATAS DE METAL",
	"CUTLERY HANDTOOLS GENERAL HARDWARE": "CUBIERTOS, HERRAMIENTAS MANUALES Y FERRETERÍA EN GENERAL",
	"HEATING EQUIP EXCEPT ELEC WARM AIR PLUMBING FIXTURES": "EQUIPOS DE CALEFACCIÓN, EXCEPTO ELÉCTRICOS, AIRE CALIENTE Y ACCESORIOS DE Plomería",
	"HEATING EQUIPMENT EXCEPT ELECTRIC WARM AIR FURNACES": "EQUIPOS DE CALEFACCIÓN, EXCEPTO HORNOS ELÉCTRICOS Y DE AIRE CALIENTE",
	"FABRICATED STRUCTURAL METAL PRODUCTS": "PRODUCTOS METÁLICOS ESTRUCTURALES FABRICADOS",
	"METAL DOORS SASH FRAMES MOLDING TRIM": "PUERTAS, MARCOS, MOLDURAS Y ACABADOS DE METAL",
	"FABRICATED PLATE WORK BOILER SHOPS ": "TRABAJO EN CHAPA FABRICADA (CALDERERÍAS)",
	"PREFABRICATED METAL BUILDINGS COMPONENTS": "CONSTRUCCIONES Y COMPONENTES METÁLICOS PREFABRICADOS",
	"METAL FORGING STAMPINGS": "FORJA Y ESTAMPACIÓN DE METALES",
	"COATING ENGRAVING ALLIED SERVICES": "RECUBRIMIENTO, GRABADO Y SERVICIOS ALIADOS",
	"ORDNANCE ACCESSORIES NO VEHICLES/GUIDED MISSILES ": "ARTÍCULOS Y ACCESORIOS (NO VEHÍCULOS/MISILES GUIADOS)",
	"MISCELLANEOUS FABRICATED METAL PRODUCTS": "PRODUCTOS METÁLICOS FABRICADOS VARIOS",
	"ENGINES TURBINES": "MOTORES Y TURBINAS",
	"FARM MACHINERY EQUIPMENT": "MAQUINARIA Y EQUIPO AGRÍCOLA",
	"LAWN GARDEN TRACTORS HOME LAWN GARDEN EQUIPMENT": "TRACTORES DE CÉSPED Y JARDÍN Y EQUIPOS DE CÉSPED Y JARDÍN PARA EL HOGAR",
	"CONSTRUCTION MINING MATERIALS HANDLING MACHINERY EQUIP": "MAQUINARIA Y EQUIPOS PARA CONSTRUCCIÓN, MINERÍA Y MANIPULACIÓN DE MATERIALES",
	"CONSTRUCTION MACHINERY EQUIP": "MAQUINARIA Y EQUIPO DE CONSTRUCCIÓN",
	"OIL GAS FILED MACHINERY EQUIPMENT": "MAQUINARIA Y EQUIPOS ARCHIVADOS DE PETRÓLEO Y GAS",
	"INDUSTRIAL TRUCKS TRACTORS TRAILERS STACKERS": "CAMIONES INDUSTRIALES TRACTORES REMOLQUES Y APILADORES",
	"METALWORKING MACHINERY EQUIPMENT": "MAQUINARIA Y EQUIPOS PARA TRABAJO METAL",
	"MACHINE TOOLS METAL CUTTING TYPES": "MÁQUINAS HERRAMIENTA, TIPOS DE CORTE DE METALES",
	"SPECIAL INDUSTRY MACHINERY NO METALWORKING MACHINERY ": "MAQUINARIA ESPECIAL INDUSTRIA (NO MAQUINARIA METALWORKING)",
	"PRINTING TRADES MACHINERY EQUIPMENT": "MAQUINARIA Y EQUIPOS PARA IMPRESIÓN",
	"SPECIAL INDUSTRY MACHINERY ": "MAQUINARIA ESPECIAL INDUSTRIA, NEC",
	"GENERAL INDUSTRIAL MACHINERY EQUIPMENT": "MAQUINARIA Y EQUIPOS INDUSTRIALES EN GENERAL",
	"PUMPS PUMPING EQUIPMENT": "BOMBAS Y EQUIPOS DE BOMBEO",
	"BALL ROLLER BEARINGS": "RODAMIENTOS DE BOLAS Y RODILLOS",
	"INDUSTRIAL COMMERCIAL FANS BLOWERS AIR PURIFYING EQUIP": "VENTILADORES Y SOPLADORES Y EQUIPOS DE PURIFICACIÓN DE AIRE INDUSTRIALES Y COMERCIALES",
	"GENERAL INDUSTRIAL MACHINERY EQUIPMENT ": "MAQUINARIA Y EQUIPOS INDUSTRIALES EN GENERAL, NEC",
	"COMPUTER OFFICE EQUIPMENT": "EQUIPOS DE COMPUTADORA Y OFICINA",
	"ELECTRONIC COMPUTERS": "COMPUTADORAS ELECTRÓNICAS",
	"COMPUTER STORAGE DEVICES": "DISPOSITIVOS DE ALMACENAMIENTO INFORMÁTICO",
	"COMPUTER COMMUNICATIONS EQUIPMENT": "EQUIPOS DE COMUNICACIONES INFORMÁTICAS",
	"COMPUTER PERIPHERAL EQUIPMENT ": "EQUIPOS PERIFÉRICOS INFORMÁTICOS, NEC",
	"CALCULATING ACCOUNTING MACHINES NO ELECTRONIC COMPUTERS ": "MÁQUINAS DE CÁLCULO Y CONTABILIDAD (NO COMPUTADORAS ELECTRÓNICAS)",
	"OFFICE MACHINES ": "MÁQUINAS DE OFICINA, NEC",
	"REFRIGERATION SERVICE INDUSTRY MACHINERY": "MAQUINARIA PARA LA INDUSTRIA DE REFRIGERACIÓN Y SERVICIOS",
	"AIR COND WARM AIR HEATING EQUIP COMM INDL REFRIG EQUIP": "EQUIPO DE CALEFACCIÓN DE AIRE ACONDICIONADO Y AIRE CALIENTE Y EQUIPO DE REFRIGERACIÓN INDL Y COMUNICACIÓN",
	"MISC INDUSTRIAL COMMERCIAL MACHINERY EQUIPMENT": "MAQUINARIA Y EQUIPOS VARIOS INDUSTRIALES Y COMERCIALES",
	"ELECTRONIC OTHER ELECTRICAL EQUIPMENT NO COMPUTER EQUIP ": "EQUIPOS ELECTRÓNICOS Y OTROS EQUIPOS ELÉCTRICOS (SIN EQUIPO DE COMPUTADORA)",
	"POWER DISTRIBUTION SPECIALTY TRANSFORMERS": "TRANSFORMADORES DE ENERGÍA, DISTRIBUCIÓN Y ESPECIALIDADES",
	"SWITCHGEAR SWITCHBOARD APPARATUS": "APARATOS DE DISTRIBUCIÓN Y CUADRO DE INTERRUPCIÓN",
	"ELECTRICAL INDUSTRIAL APPARATUS": "APARATOS INDUSTRIALES ELÉCTRICOS",
	"MOTORS GENERATORS": "MOTORES Y GENERADORES",
	"HOUSEHOLD APPLIANCES": "ELECTRODOMÉSTICOS",
	"ELECTRIC HOUSEWARES FANS": "ARTÍCULOS ELÉCTRICOS Y VENTILADORES",
	"ELECTRIC LIGHTING WIRING EQUIPMENT": "EQUIPO DE ILUMINACIÓN Y CABLEADO ELÉCTRICO",
	"HOUSEHOLD AUDIO VIDEO EQUIPMENT": "EQUIPOS DE AUDIO Y VIDEO PARA EL HOGAR",
	"TELEPHONE TELEGRAPH APPARATUS": "APARATOS DE TELÉFONO Y TELÉGRAFO",
	"RADIO TV BROADCASTING COMMUNICATIONS EQUIPMENT": "EQUIPOS DE COMUNICACIONES Y DIFUSIÓN DE RADIO Y TV",
	"COMMUNICATIONS EQUIPMENT ": "EQUIPOS DE COMUNICACIONES, NEC",
	"ELECTRONIC COMPONENTS ACCESSORIES": "COMPONENTES ELECTRÓNICOS Y ACCESORIOS",
	"PRINTED CIRCUIT BOARDS": "TARJETAS DE CIRCUITO IMPRESO",
	"SEMICONDUCTORS RELATED DEVICES": "SEMICONDUCTORES Y DISPOSITIVOS RELACIONADOS",
	"ELECTRONIC COILS TRANSFORMERS OTHER INDUCTORS": "BOBINAS, TRANSFORMADORES Y OTROS INDUCTORES ELECTRÓNICOS",
	"ELECTRONIC CONNECTORS": "CONECTORES ELECTRÓNICOS",
	"ELECTRONIC COMPONENTS ": "COMPONENTES ELECTRÓNICOS, NEC",
	"MISCELLANEOUS ELECTRICAL MACHINERY EQUIPMENT SUPPLIES": "MAQUINARIA, EQUIPOS Y SUMINISTROS ELÉCTRICOS VARIOS",
	"MOTOR VEHICLES PASSENGER CAR BODIES": "VEHICULOS DE MOTOR Y CARROCERIA DE TURISMOS",
	"TRUCK BUS BODIES": "CARROCERIA DE CAMIONES Y AUTOBUSES",
	"MOTOR VEHICLE PARTS ACCESSORIES": "PIEZAS Y ACCESORIOS PARA VEHÍCULOS DE MOTOR",
	"TRUCK TRAILERS": "REMOLQUES DE CAMIONES",
	"MOTOR HOMES": "CASAS MOTORES",
	"AIRCRAFT PARTS": "AERONAVES Y PIEZAS",
	"AIRCRAFT": "AERONAVE",
	"AIRCRAFT ENGINES ENGINE PARTS": "MOTORES DE AERONAVES Y PIEZAS DE MOTORES",
	"AIRCRAFT PART AUXILIARY EQUIPMENT ": "PARTE DE AERONAVE Y EQUIPO AUXILIAR, NEC",
	"SHIP BOAT BUILDING REPAIRING": "CONSTRUCCIÓN Y REPARACIÓN DE BARCOS Y BARCOS",
	"RAILROAD EQUIPMENT": "EQUIPO FERROVIARIO",
	"MOTORCYCLES BICYCLES PARTS": "MOTOCICLETAS, BICICLETAS Y REPUESTOS",
	"GUIDED MISSILES SPACE VEHICLES PARTS": "MISILES GUIADOS Y VEHÍCULOS ESPACIALES Y PARTES",
	"MISCELLANEOUS TRANSPORTATION EQUIPMENT": "EQUIPOS DE TRANSPORTE VARIOS",
	"SEARCH DETECTION NAVIGATION GUIDANCE AERONAUTICAL SYS": "BÚSQUEDA, DETECCIÓN, NAVEGACIÓN, GUIADO, SISTEMA AERONÁUTICO",
	"LABORATORY APPARATUS FURNITURE": "APARATOS Y MUEBLES DE LABORATORIO",
	"AUTO CONTROLS FOR REGULATING RESIDENTIAL COMML ENVIRONMENT": "CONTROLES AUTOMÁTICOS PARA REGULAR EL ENTORNO RESIDENCIAL Y COMUNITARIO",
	"INDUSTRIAL INSTRUMENTS FOR MEASUREMENT DISPLAY AND CONTROL": "INSTRUMENTOS INDUSTRIALES DE MEDIDA, VISUALIZACIÓN Y CONTROL",
	"TOTALIZING FLUID METERS COUNTING DEVICES": "MEDIDORES TOTALIZADORES DE FLUIDOS Y DISPOSITIVOS DE CONTEO",
	"INSTRUMENTS FOR MEAS TESTING OF ELECTRICITY ELEC SIGNALS": "INSTRUMENTOS PARA MEDIDAS Y PRUEBAS DE ELECTRICIDAD Y SEÑALES ELÉCTRICAS",
	"LABORATORY ANALYTICAL INSTRUMENTS": "INSTRUMENTOS ANALITICOS DE LABORATORIO",
	"OPTICAL INSTRUMENTS LENSES": "INSTRUMENTOS ÓPTICOS Y LENTES",
	"MEASURING CONTROLLING DEVICES ": "DISPOSITIVOS DE MEDICIÓN Y CONTROL, NEC",
	"SURGICAL MEDICAL INSTRUMENTS APPARATUS": "INSTRUMENTOS Y APARATOS QUIRÚRGICOS Y MÉDICOS",
	"ORTHOPEDIC PROSTHETIC SURGICAL APPLIANCES SUPPLIES": "APARATOS Y SUMINISTROS ORTOPÉDICOS, PROTÉSICOS Y QUIRÚRGICOS",
	"DENTAL EQUIPMENT SUPPLIES": "EQUIPOS Y SUMINISTROS DENTALES",
	"X RAY APPARATUS TUBES RELATED IRRADIATION APPARATUS": "APARATOS Y TUBOS DE RAYOS X Y APARATOS DE IRRADIACIÓN RELACIONADOS",
	"ELECTROMEDICAL ELECTROTHERAPEUTIC APPARATUS": "APARATOS ELECTROMÉDICOS Y ELECTROTERAPÉUTICOS",
	"OPHTHALMIC GOODS": "PRODUCTOS OFTALMICOS",
	"PHOTOGRAPHIC EQUIPMENT SUPPLIES": "EQUIPOS Y SUMINISTROS FOTOGRÁFICOS",
	"WATCHES CLOCKS CLOCKWORK OPERATED DEVICES/PARTS": "RELOJES, DISPOSITIVOS/PARTES ACCIONADOS POR RELOJES",
	"JEWELRY SILVERWARE PLATED WARE": "JOYERÍA, PLATERÍA Y CHAPADO",
	"DOLLS STUFFED TOYS": "MUÑECAS Y PELUCHES",
	"GAMES TOYS CHILDREN'S VEHICLES NO DOLLS BICYCLES ": "JUEGOS, JUGUETES Y VEHÍCULOS PARA NIÑOS (NO MUÑECAS NI BICICLETAS)",
	"MISCELLANEOUS MANUFACTURING INDUSTRIES": "INDUSTRIAS FABRICANTES VARIAS",
	"RAILROADS LINE HAUL OPERATING": "FERROCARRILES, OPERACIÓN DE TRANSPORTE EN LÍNEA",
	"RAILROAD SWITCHING TERMINAL ESTABLISHMENTS": "ESTABLECIMIENTOS DE CONMUTACIÓN Y TERMINALES DE FERROCARRIL",
	"TRUCKING COURIER SERVICES NO AIR ": "SERVICIOS DE CAMIÓN Y MENSAJERÍA (SIN AÉREO)",
	"TRUCKING NO LOCAL ": "CAMIÓN (NO LOCAL)",
	"WATER TRANSPORTATION": "TRANSPORTE ACUÁTICO",
	"DEEP SEA FOREIGN TRANSPORTATION OF FREIGHT": "TRANSPORTE DE MERCANCÍAS EN MAR PROFUNDO",
	"AIR TRANSPORTATION SCHEDULED": "TRANSPORTE AÉREO PROGRAMADO",
	"AIR COURIER SERVICES": "SERVICIOS DE MENSAJERÍA AÉREA",
	"AIR TRANSPORTATION NONSCHEDULED": "TRANSPORTE AÉREO NO PROGRAMADO",
	"AIRPORTS FLYING FIELDS AIRPORT TERMINAL SERVICES": "AEROPUERTOS, CAMPOS DE VUELO Y SERVICIOS DE TERMINALES AEROPUERTUARIAS",
	"PIPE LINES NO NATURAL GAS ": "TUBERÍAS (NO GAS NATURAL)",
	"TRANSPORTATION SERVICES": "SERVICIOS DE TRANSPORTE",
	"ARRANGEMENT OF TRANSPORTATION OF FREIGHT CARGO": "DISPOSICIÓN DE TRANSPORTE DE CARGA Y CARGA",
	"RADIO TELEPHONE COMMUNICATIONS": "COMUNICACIONES RADIOTELEFÓNICAS",
	"TELEPHONE COMMUNICATIONS NO RADIO TELEPHONE ": "COMUNICACIONES TELEFÓNICAS (NO RADIO TELÉFONO)",
	"TELEGRAPH OTHER MESSAGE COMMUNICATIONS": "TELÉGRAFO Y OTRAS COMUNICACIONES DE MENSAJES",
	"RADIO BROADCASTING STATIONS": "ESTACIONES DE RADIODIFUSIÓN",
	"TELEVISION BROADCASTING STATIONS": "ESTACIONES DE TRANSMISIÓN DE TELEVISIÓN",
	"CABLE OTHER PAY TELEVISION SERVICES": "CABLE Y OTROS SERVICIOS DE TELEVISIÓN DE PAGO",
	"COMMUNICATION SERVICES ": "SERVICIOS DE COMUNICACIÓN, NEC",
	"ELECTRIC GAS SANITARY SERVICES": "SERVICIOS ELÉCTRICOS, GAS Y SANITARIOS",
	"ELECTRIC SERVICES": "SERVICIOS ELÉCTRICOS",
	"NATURAL GAS TRANSMISSION": "TRANSMISIÓN DE GAS NATURAL",
	"NATURAL GAS TRANSMISSION DISTRIBUTION": "TRANSMISIÓN Y DISTRIBUCIÓN DE GAS NATURAL",
	"NATURAL GAS DISTRIBUTION": "DISTRIBUCIÓN DE GAS NATURAL",
	"ELECTRIC OTHER SERVICES COMBINED": "SERVICIOS ELÉCTRICOS Y OTROS COMBINADOS",
	"GAS OTHER SERVICES COMBINED": "GAS Y OTROS SERVICIOS COMBINADOS",
	"WATER SUPPLY": "SUMINISTRO DE AGUA",
	"SANITARY SERVICES": "SERVICIOS SANITARIOS",
	"REFUSE SYSTEMS": "SISTEMAS DE BASURA",
	"HAZARDOUS WASTE MANAGEMENT": "GESTIÓN DE RESIDUOS PELIGROSOS",
	"COGENERATION SERVICES SMALL POWER PRODUCERS": "SERVICIOS DE COGENERACIÓN Y PEQUEÑOS PRODUCTORES DE ENERGÍA",
	"WHOLESALE DURABLE GOODS": "BIENES DURADEROS AL POR MAYOR",
	"WHOLESALE MOTOR VEHICLES MOTOR VEHICLE PARTS SUPPLIES": "AL POR MAYOR-VEHÍCULOS DE MOTOR Y PIEZAS Y SUMINISTROS PARA VEHÍCULOS DE MOTOR",
	"WHOLESALE MOTOR VEHICLE SUPPLIES NEW PARTS": "VENTA AL POR MAYOR-SUMINISTROS PARA VEHÍCULOS DE MOTOR Y PIEZAS NUEVAS",
	"WHOLESALE LUMBER OTHER CONSTRUCTION MATERIALS": "MAYORISTA-MADERA Y OTROS MATERIALES DE CONSTRUCCIÓN",
	"WHOLESALE LUMBER PLYWOOD MILLWORK WOOD PANELS": "VENTA AL POR MAYOR DE MADERA, CONTRACHAPADO, CARPINTERÍA Y PANELES DE MADERA",
	"WHOLESALE PROFESSIONAL COMMERCIAL EQUIPMENT SUPPLIES": "VENTA AL POR MAYOR DE EQUIPOS Y SUMINISTROS PROFESIONALES Y COMERCIALES",
	"WHOLESALE COMPUTER PERIPHERAL EQUIPMENT SOFTWARE": "VENTA AL POR MAYOR-EQUIPOS INFORMÁTICOS Y PERIFÉRICOS Y SOFTWARE",
	"WHOLESALE MEDICAL DENTAL HOSPITAL EQUIPMENT SUPPLIES": "VENTA AL POR MAYOR DE EQUIPOS Y SUMINISTROS MÉDICOS, DENTALES Y HOSPITALARIOS",
	"WHOLESALE METALS SERVICE CENTERS OFFICES": "CENTROS Y OFICINAS DE SERVICIO DE METALES AL POR MAYOR",
	"WHOLESALE ELECTRICAL APPARATUS EQUIPMENT WIRING SUPPLIES": "VENTA AL POR MAYOR DE APARATOS Y EQUIPOS ELÉCTRICOS, SUMINISTROS DE CABLEADO",
	"WHOLESALE ELECTRONIC PARTS EQUIPMENT ": "VENTA AL POR MAYOR-PIEZAS Y EQUIPOS ELECTRÓNICOS, NEC",
	"WHOLESALE HARDWARE PLUMBING HEATING EQUIPMENT SUPPLIES": "VENTA AL POR MAYOR-FERRETERÍA Y FONTANERÍA Y EQUIPOS Y SUMINISTROS DE CALEFACCIÓN",
	"WHOLESALE HARDWARE": "MAYORISTA-FERRETERÍA",
	"WHOLESALE MACHINERY EQUIPMENT SUPPLIES": "MAYORISTA-MAQUINARIA, EQUIPOS Y SUMINISTROS",
	"WHOLESALE INDUSTRIAL MACHINERY EQUIPMENT": "MAYORISTA-MAQUINARIA Y EQUIPOS INDUSTRIALES",
	"WHOLESALE MISC DURABLE GOODS": "VARIOS BIENES DURADEROS AL POR MAYOR",
	"WHOLESALE JEWELRY WATCHES PRECIOUS STONES METALS": "MAYORISTA-JOYAS, RELOJES, PIEDRAS PRECIOSAS Y METALES",
	"WHOLESALE DURABLE GOODS ": "BIENES DURADEROS AL POR MAYOR, NEC",
	"WHOLESALE PAPER AND PAPER PRODUCTS": "MAYORISTA-PAPEL Y PRODUCTOS DE PAPEL",
	"WHOLESALE DRUGS PROPRIETARIES DRUGGISTS' SUNDRIES": "PROPIETARIOS DE MEDICAMENTOS AL POR MAYOR Y ARTÍCULOS VARIOS DE FARMACÉUTICOS",
	"WHOLESALE APPAREL PIECE GOODS NOTIONS": "VENTA AL POR MAYOR DE ROPA, ARTÍCULOS Y ARTÍCULOS",
	"WHOLESALE GROCERIES RELATED PRODUCTS": "MAYORISTA-COMESTIBLES Y PRODUCTOS RELACIONADOS",
	"WHOLESALE GROCERIES GENERAL LINE": "MAYORISTA-COMESTIBLES Y LÍNEA GENERAL",
	"WHOLESALE FARM PRODUCT RAW MATERIALS": "MATERIAS PRIMAS DE PRODUCTOS AGRICOLAS AL POR MAYOR",
	"WHOLESALE CHEMICALS ALLIED PRODUCTS": "MAYORISTA-QUIMICOS Y PRODUCTOS AFINES",
	"WHOLESALE PETROLEUM BULK STATIONS TERMINALS": "MAYORISTA-ESTACIONES Y TERMINALES DE GRANEL DE PETRÓLEO",
	"WHOLESALE PETROLEUM PETROLEUM PRODUCTS NO BULK STATIONS ": "MAYORISTA-PETROLEO Y PRODUCTOS PETROLEROS (NO ESTACIONES A GRANEL)",
	"WHOLESALE BEER WINE DISTILLED ALCOHOLIC BEVERAGES": "MAYORISTA-CERVEZA, VINO Y BEBIDAS ALCOHÓLICAS DESTILADAS",
	"WHOLESALE MISCELLANEOUS NONDURABLE GOODS": "VARIOS BIENES NO DURADEROS AL POR MAYOR",
	"RETAIL BUILDING MATERIALS HARDWARE GARDEN SUPPLY": "MATERIALES DE CONSTRUCCIÓN AL POR MENOR, FERRETERÍA, SUMINISTROS DE JARDÍN",
	"RETAIL LUMBER OTHER BUILDING MATERIALS DEALERS": "DISTRIBUIDORES MINORISTAS DE MADERA Y OTROS MATERIALES DE CONSTRUCCIÓN",
	"RETAIL DEPARTMENT STORES": "TIENDAS POR DEPARTAMENTO MINORISTAS",
	"RETAIL VARIETY STORES": "TIENDAS MINORISTAS DE VARIEDAD",
	"RETAIL MISC GENERAL MERCHANDISE STORES": "TIENDAS MINORISTAS DE MERCANCÍAS GENERALES",
	"RETAIL FOOD STORES": "TIENDAS DE ALIMENTOS AL POR MENOR",
	"RETAIL GROCERY STORES": "TIENDAS DE COMESTIBLES AL POR MENOR",
	"RETAIL AUTO DEALERS GASOLINE STATIONS": "CONCESIONARIOS MINORISTAS DE AUTOMÓVILES Y ESTACIONES DE GASOLINA",
	"RETAIL AUTO HOME SUPPLY STORES": "TIENDAS MINORISTAS DE ARTÍCULOS PARA EL AUTOMÓVIL Y EL HOGAR",
	"RETAIL APPAREL ACCESSORY STORES": "TIENDAS MINORISTAS DE ROPA Y ACCESORIOS",
	"RETAIL WOMEN'S CLOTHING STORES": "TIENDAS MINORISTAS DE ROPA DE MUJER",
	"RETAIL FAMILY CLOTHING STORES": "TIENDAS MINORISTAS DE ROPA FAMILIAR",
	"RETAIL SHOE STORES": "TIENDAS DE CALZADO AL POR MENOR",
	"RETAIL HOME FURNITURE FURNISHINGS EQUIPMENT STORES": "TIENDAS MINORISTAS DE MUEBLES, MUEBLES Y EQUIPOS PARA EL HOGAR",
	"RETAIL FURNITURE STORES": "TIENDAS MINORISTAS DE MUEBLES",
	"RETAIL RADIO TV CONSUMER ELECTRONICS STORES": "TIENDAS MINORISTAS DE RADIO TV Y ELECTRÓNICA DE CONSUMO",
	"RETAIL COMPUTER COMPUTER SOFTWARE STORES": "TIENDAS MINORISTAS DE INFORMÁTICAS Y SOFTWARE DE INFORMÁTICA",
	"RETAIL COMPUTER PRERECORDED TAPE STORES": "MINORISTAS: TIENDAS DE INFORMÁTICAS Y CINTAS PREGRABADAS",
	"RETAIL EATING DRINKING PLACES": "LUGARES PARA COMER Y BEBER AL POR MENOR",
	"RETAIL EATING PLACES": "LUGARES PARA COMER AL POR MENOR",
	"RETAIL MISCELLANEOUS RETAIL": "MINORISTA-VARIOS MINORISTA",
	"RETAIL DRUG STORES AND PROPRIETARY STORES": "FARMACIA AL POR MENOR Y TIENDAS PROPIETARIAS",
	"RETAIL MISCELLANEOUS SHOPPING GOODS STORES": "TIENDAS DE ARTÍCULOS DE COMPRA AL POR MENOR-VARIOS",
	"RETAIL JEWELRY STORES": "TIENDAS DE JOYERÍA AL POR MENOR",
	"RETAIL HOBBY TOY GAME SHOPS": "TIENDAS MINORISTAS DE PASATIEMPOS, JUGUETES Y JUEGOS",
	"RETAIL CATALOG MAIL ORDER HOUSES": "CASAS DE CATÁLOGO MINORISTA Y PEDIDO POR CORREO",
	"RETAIL RETAIL STORES ": "TIENDAS MINORISTAS-MENORISTAS, NEC",
	"NATIONAL COMMERCIAL BANKS": "BANCOS COMERCIALES NACIONALES",
	"STATE COMMERCIAL BANKS": "BANCOS COMERCIALES ESTATALES",
	"COMMERCIAL BANKS ": "BANCOS COMERCIALES, NEC",
	"SAVINGS INSTITUTION FEDERALLY CHARTERED": "INSTITUCIÓN DE AHORRO, AUTORIZADA FEDERALMENTE",
	"SAVINGS INSTITUTIONS NOT FEDERALLY CHARTERED": "INSTITUCIONES DE AHORRO, NO AUTORIZADAS FEDERALMENTE",
	"FUNCTIONS RELATED TO DEPOSITORY BANKING ": "FUNCIONES RELACIONADAS CON LA BANCA DE DEPÓSITO, NEC",
	"FEDERAL FEDERALLY SPONSORED CREDIT AGENCIES": "AGENCIAS DE CRÉDITO FEDERALES Y PATROCINADAS FEDERALMENTE",
	"PERSONAL CREDIT INSTITUTIONS": "INSTITUCIONES DE CRÉDITO PERSONAL",
	"SHORT TERM BUSINESS CREDIT INSTITUTIONS": "INSTITUCIONES DE CRÉDITO EMPRESARIAL A CORTO PLAZO",
	"MISCELLANEOUS BUSINESS CREDIT INSTITUTION": "INSTITUCIÓN DE CRÉDITO EMPRESARIAL VARIOS",
	"MORTGAGE BANKERS LOAN CORRESPONDENTS": "BANCOS HIPOTECARIOS Y CORRESPONSALES DE PRÉSTAMOS",
	"LOAN BROKERS": "CORREDORES DE PRÉSTAMO",
	"FINANCE LESSORS": "ARRENDADORES DE FINANZAS",
	"FINANCE SERVICES": "SERVICIOS FINANCIEROS",
	"SECURITY COMMODITY BROKERS DEALERS EXCHANGES SERVICES": "CORREDORES, DISTRIBUIDORES, INTERCAMBIOS Y SERVICIOS DE SEGURIDAD Y MATERIAS PRIMAS",
	"SECURITY BROKERS DEALERS FLOTATION COMPANIES": "CORREDORES DE SEGURIDAD, DISTRIBUIDORES Y EMPRESAS DE FLOTACIÓN",
	"State location ": "Ubicación del estado:",
	"INVESTMENT ADVICE": "ASESORAMIENTO EN INVERSIONES",
	"LIFE INSURANCE": "SEGURO DE VIDA",
	"ACCIDENT HEALTH INSURANCE": "SEGURO DE ACCIDENTES Y SALUD",
	"HOSPITAL MEDICAL SERVICE PLANS": "PLANES DE SERVICIOS MÉDICOS Y HOSPITALARIOS",
	"FIRE MARINE CASUALTY INSURANCE": "SEGURO CONTRA INCENDIOS, MARÍTIMO Y CONTRA VÍCTIMAS",
	"SURETY INSURANCE": "SEGURO DE CAUCIÓN",
	"TITLE INSURANCE": "SEGURO DE TÍTULO",
	"INSURANCE CARRIERS ": "COMPAÑÍAS DE SEGUROS, NEC",
	"INSURANCE AGENTS BROKERS SERVICES": "AGENTES DE SEGUROS CORREDORES Y SERVICIOS",
	"REAL ESTATE": "BIENES RAÍCES",
	"REAL ESTATE OPERATORS NO DEVELOPERS LESSORS": "OPERADORES INMOBILIARIOS (NO DESARROLLADORES) Y ARRENDADORES",
	"OPERATORS OF NONRESIDENTIAL BUILDINGS": "OPERADORES DE EDIFICIOS NO RESIDENCIALES",
	"OPERATORS OF APARTMENT BUILDINGS": "OPERADORES DE EDIFICIOS DE APARTAMENTOS",
	"LESSORS OF REAL PROPERTY ": "ARRENDADORES DE BIENES INMUEBLES, NEC",
	"REAL ESTATE AGENTS MANAGERS FOR OTHERS ": "AGENTES Y ADMINISTRADORES INMOBILIARIOS (PARA TERCEROS)",
	"LAND SUBDIVIDERS DEVELOPERS NO CEMETERIES ": "SUBDIVISORES Y DESARROLLADORES DE TERRENOS (NO CEMENTERIOS)",
	"BLANK CHECKS": "CHEQUES EN BLANCO",
	"PATENT OWNERS LESSORS": "PROPIETARIOS DE PATENTES Y ARRENDADORES",
	"MINERAL ROYALTY TRADERS": "COMERCIANTES DE REGALÍAS DE MINERALES",
	"REAL ESTATE INVESTMENT TRUSTS": "FIDEICOMISOS DE INVERSIÓN INMOBILIARIA",
	"INVESTORS ": "INVERSORES, NEC",
	"HOTELS ROOMING HOUSE CAMPS OTHER LODGING PLACES": "HOTELES, PENSIONES, CAMPAMENTOS Y OTROS LUGARES DE ALOJAMIENTO",
	"HOTELS MOTELS": "HOTELES Y MOTELES",
	"SERVICES PERSONAL SERVICES": "SERVICIOS-SERVICIOS PERSONALES",
	"SERVICES ADVERTISING": "SERVICIOS-PUBLICIDAD",
	"SERVICES ADVERTISING AGENCIES": "SERVICIOS-AGENCIAS DE PUBLICIDAD",
	"SERVICES CONSUMER CREDIT REPORTING COLLECTION AGENCIES": "SERVICIOS-INFORMES DE CRÉDITO DEL CONSUMIDOR, AGENCIAS DE COBRO",
	"SERVICES MAILING REPRODUCTION COMMERCIAL ART PHOTOGRAPHY": "SERVICIOS DE CORREO, REPRODUCCIÓN, ARTE COMERCIAL Y FOTOGRAFÍA",
	"SERVICES DIRECT MAIL ADVERTISING SERVICES": "SERVICIOS-SERVICIOS DE PUBLICIDAD POR CORREO DIRECTO",
	"SERVICES TO DWELLINGS OTHER BUILDINGS": "SERVICIOS A VIVIENDAS Y OTROS EDIFICIOS",
	"SERVICES EQUIPMENT RENTAL LEASING ": "SERVICIOS-ALQUILER Y ARRENDAMIENTO DE EQUIPOS, NEC",
	"SERVICES EMPLOYMENT AGENCIES": "SERVICIOS-AGENCIAS DE EMPLEO",
	"SERVICES HELP SUPPLY SERVICES": "SERVICIOS-SUMINISTRO DE SERVICIOS DE AYUDA",
	"SERVICES COMPUTER PROGRAMMING DATA PROCESSING ETC.": "SERVICIOS-PROGRAMACIÓN INFORMÁTICA, PROCESAMIENTO DE DATOS, ETC.",
	"SERVICES COMPUTER PROGRAMMING SERVICES": "SERVICIOS-SERVICIOS DE PROGRAMACIÓN INFORMÁTICA",
	"SERVICES PREPACKAGED SOFTWARE": "SERVICIOS-SOFTWARE PREEMPAQUETADO",
	"SERVICES COMPUTER INTEGRATED SYSTEMS DESIGN": "SERVICIOS-DISEÑO DE SISTEMAS INTEGRADOS INFORMÁTICOS",
	"SERVICES COMPUTER PROCESSING DATA PREPARATION": "SERVICIOS-PROCESAMIENTO INFORMÁTICO Y PREPARACIÓN DE DATOS",
	"SERVICES MISCELLANEOUS BUSINESS SERVICES": "SERVICIOS-SERVICIOS EMPRESARIALES VARIOS",
	"SERVICES DETECTIVE GUARD ARMORED CAR SERVICES": "SERVICIOS-SERVICIOS DE DETECTIVES, GUARDIAS Y VEHÍCULOS BLINDADOS",
	"SERVICES TELEPHONE INTERCONNECT SYSTEMS": "SERVICIOS-SISTEMAS DE INTERCONEXIÓN TELEFÓNICA",
	"SERVICES BUSINESS SERVICES ": "SERVICIOS-SERVICIOS EMPRESARIALES, NEC",
	"SERVICES AUTOMOTIVE REPAIR SERVICES PARKING": "SERVICIOS-REPARACIÓN, SERVICIOS Y APARCAMIENTO DE AUTOMOTRICES",
	"SERVICES AUTO RENTAL LEASING NO DRIVERS ": "SERVICIOS-ALQUILER Y ARRENDAMIENTO DE AUTOMÓVILES (SIN CONDUCTORES)",
	"SERVICES MISCELLANEOUS REPAIR SERVICES": "SERVICIOS-SERVICIOS DE REPARACIÓN VARIOS",
	"SERVICES MOTION PICTURE VIDEO TAPE PRODUCTION": "SERVICIOS-PRODUCCIÓN DE CINTAS DE VIDEO Y IMAGEN EN MOVIMIENTO",
	"SERVICES ALLIED TO MOTION PICTURE PRODUCTION": "SERVICIOS ALIADOS A LA PRODUCCIÓN DE IMÁGENES EN MOVIMIENTO",
	"SERVICES MOTION PICTURE VIDEO TAPE DISTRIBUTION": "SERVICIOS-DISTRIBUCIÓN DE CINTAS DE VIDEO Y IMÁGENES EN MOVIMIENTO",
	"SERVICES MOTION PICTURE THEATERS": "SERVICIOS-SALA DE CINE",
	"SERVICES VIDEO TAPE RENTAL": "SERVICIOS-ALQUILER DE CINTAS DE VIDEO",
	"SERVICES AMUSEMENT RECREATION SERVICES": "SERVICIOS-SERVICIOS DE DIVERSIÓN Y RECREACIÓN",
	"SERVICES RACING INCLUDING TRACK OPERATION": "SERVICIOS-CARRERAS, INCLUYENDO OPERACIÓN DE PISTA",
	"SERVICES MISCELLANEOUS AMUSEMENT RECREATION": "SERVICIOS-DIVERSIÓN Y RECREACIÓN VARIOS",
	"SERVICES MEMBERSHIP SPORTS RECREATION CLUBS": "SERVICIOS-MEMBRESÍA CLUBES DEPORTIVOS Y RECREACIÓN",
	"SERVICES HEALTH SERVICES": "SERVICIOS-SERVICIOS DE SALUD",
	"SERVICES OFFICES CLINICS OF DOCTORS OF MEDICINE": "SERVICIOS-CONSULTORIOS Y CLÍNICAS DE DOCTORES EN MEDICINA",
	"SERVICES NURSING PERSONAL CARE FACILITIES": "SERVICIOS-INSTALACIONES DE ENFERMERÍA Y CUIDADO PERSONAL",
	"SERVICES SKILLED NURSING CARE FACILITIES": "CENTROS DE ATENCIÓN DE ENFERMERÍA ESPECIALIZADOS EN SERVICIOS",
	"SERVICES HOSPITALS": "SERVICIOS-HOSPITALES",
	"SERVICES GENERAL MEDICAL SURGICAL HOSPITALS ": "SERVICIOS-HOSPITALES MÉDICOS Y QUIRÚRGICOS GENERALES, NEC",
	"SERVICES MEDICAL LABORATORIES": "SERVICIOS-LABORATORIOS MÉDICOS",
	"SERVICES HOME HEALTH CARE SERVICES": "SERVICIOS-SERVICIOS DE ATENCIÓN MÉDICA A DOMICILIO",
	"SERVICES MISC HEALTH ALLIED SERVICES ": "SERVICIOS-MISC SALUD Y SERVICIOS AFINES, NEC",
	"SERVICES SPECIALTY OUTPATIENT FACILITIES ": "SERVICIOS-INSTALACIONES ESPECIALIZADAS PARA PACIENTES AMBULATORIOS, NEC",
	"SERVICES LEGAL SERVICES": "SERVICIOS-SERVICIOS LEGALES",
	"SERVICES EDUCATIONAL SERVICES": "SERVICIOS-SERVICIOS EDUCATIVOS",
	"SERVICES CHILD DAY CARE SERVICES": "SERVICIOS-SERVICIOS DE GUARDERÍA INFANTIL",
	"SERVICES ENGINEERING ACCOUNTING RESEARCH MANAGEMENT": "SERVICIOS-INGENIERÍA, CONTABILIDAD, INVESTIGACIÓN, GESTIÓN",
	"SERVICES ENGINEERING SERVICES": "SERVICIOS-SERVICIOS DE INGENIERÍA",
	"SERVICES COMMERCIAL PHYSICAL BIOLOGICAL RESEARCH": "SERVICIOS-INVESTIGACIÓN FÍSICA Y BIOLÓGICA COMERCIAL",
	"SERVICES TESTING LABORATORIES": "SERVICIOS-LABORATORIOS DE ENSAYOS",
	"SERVICES MANAGEMENT SERVICES": "SERVICIOS-SERVICIOS DE GESTIÓN",
	"SERVICES MANAGEMENT CONSULTING SERVICES": "SERVICIOS-SERVICIOS DE CONSULTORÍA EN GESTIÓN",
	"SERVICES FACILITIES SUPPORT MANAGEMENT SERVICES": "SERVICIOS-SERVICIOS DE GESTIÓN DE APOYO A INSTALACIONES",
	"UNKNOWN SIC 8880": "SIC DESCONOCIDO - 8880",
	"SERVICES SERVICES ": "SERVICIOS-SERVICIOS, NEC",
	"SPORTING ATHLETIC GOODS": "ARTICULOS DEPORTIVOS Y ATLÉTICOS",
	"OIL ROYALTY TRADERS": "COMERCIANTES DE REGALÍAS DEL PETRÓLEO",
	"COMMUNICATIONS SERVICES, NEC": "SERVICIOS DE COMUNICACIONES",
	"REAL ESTATE CONSTRUCTION": "BIENES RAÍCES Y CONSTRUCCIÓN",
	"INDUSTRIAL APPLICATIONS SERVICES": "APLICACIONES Y SERVICIOS INDUSTRIALES",
	"PHONOGRAPH RECORDS PRERECORDED AUDIO TAPES DISKS": "DISCOS FONÓGRAFOS Y CINTAS Y DISCOS DE AUDIO PREGRABADOS",
	"N/A": "NO DISPONIBLE",	
	"all": "todo",
}

export default esSICTranslations